//Custom Button Pane
.panel-pane.pane-custom-button-pane{
    background: $wcm-bg-gray;
    margin-bottom: 20px;

    .wcm-custom-button-pane{
        width: 100%;
        height: auto;

        .custom-button__detail{
            overflow: auto;
            padding: 5%;
            display: flex;

            .cta-icon{
                fill: $wcm-bright-orange;
                color: $wcm-dark-orange;
                min-width: 82px;
                min-height: 82px;
                float: left;
                padding-right: 5%;
                margin-right: 4%;
                margin-left: 2%;
                border-right: 3px solid $wcm-border-gray;
            }

            > span{
                text-align: left;
                > *{
                    margin: 0;
                }
                p{
                    color: $wcm-black;
                }
            }
        }
    }

}
