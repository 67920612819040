//Custom CTA Pane
.wcm-custom-cta-pane{
    position: relative;

    img{
        width: 100%;
        transition: transform .2s;
    }

    .cta-caption{
        position: absolute;
        bottom: 0;
        width: 100%;
        padding: 10px 0px 10px 20px;
        min-height: 232px;

        &::before{
            position: absolute;
            left: 0;
            bottom: 0;
            content: '';
            width: 100%;
            height: 100%;
            background-color: $wcm-white;
            opacity: 0.9;
        }

        .cta-caption-text{
            position: relative;
            display: inline-block;
            max-width: 49%;

            @include breakpoint($sm) {
                max-width: 66%;
            }
        }

        .cta-social-media{
            position: absolute;
            right: 0;
            top: 0;
            text-align: center;
            width: 140px;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: stretch;
            justify-content: center;
            flex: 1;

            .social-media-button{
                width: 100%;
                display: flex;
                flex: 1;
                justify-content: center;
                align-items: center;
                max-height: 140px;
                margin-left: auto;
                border-bottom: none;

                &:after{
                    content: none;
                }

                &:hover > div:before{
                    background-color: $wcm-dark-orange;
                }

                > div{
                    display: flex;
                    flex-direction: column;
                    position: relative;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    width: 100%;
                    height: 100%;
                    padding: 15px;
                    color: $wcm-white;

                    &:before{
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        content: '';
                        width: 100%;
                        height: 100%;
                        background-color: $wcm-red;
                        mix-blend-mode: multiply;
                        pointer-events: none;
                        border-top-left-radius: 15px;
                        border-bottom-left-radius: 15px;
                        @supports (-ms-ime-align:auto) {
                            opacity: 0.8;
                        }
                    }

                    svg{
                        position: relative;
                        fill: $wcm-white;
                        width: auto;
                        height: 50%;
                        max-width: 60%;
                        max-height: 70px;
                    }

                    p{
                        position: relative;
                        max-width: 90%;
                        line-height: 20px;
                        margin-top: 5px;
                        font-weight: bold;
                    }
                }

            }
        }
        
    }
}
